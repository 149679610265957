export const ROUTES = {
  claimSuccess: '/badge/success',
  claimError: '/collect/forbidden',
  badge: '/badge',
  auth: '/auth',
  creators: '/',
  creator: '/creator',
  settings: '/settings',
  settingsMobile: '/settings/mobile',
  faq: '/faq',
  fanProfile: '/fan-profile',
  community: '/creator/community',
  challenges: '/creator/challenges',
  viewChallenge: '/creator/challenges/challenge',
  challengeCompleted: '/creator/challenges/challenge/completed',
  post: '/creator/community/post',
};
