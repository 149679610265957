'use client';

import { ROUTES } from '@constants/routes.constants';
import { useEffect } from 'react';
import { ErrorPage } from '@ui/components';
import { useLogErrorToRollbar } from './providers';

interface ErrorProps {
  error: Error;
}

export default function Error({ error }: ErrorProps) {
  const logErrorToRollbar = useLogErrorToRollbar();

  useEffect(() => {
    logErrorToRollbar({
      error,
      context: {
        route: typeof window !== 'undefined' ? window.location.pathname : '',
        userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : '',
        referrer: typeof document !== 'undefined' ? document.referrer : '',
        queryParams: typeof window !== 'undefined' ? window.location.search : '',
        additionalContext: {
          url: typeof window !== 'undefined' ? window.location.href : '',
          previousUrl: typeof document !== 'undefined' ? document.referrer : '',
          browserInfo:
            typeof navigator !== 'undefined'
              ? {
                  userAgent: navigator.userAgent,
                  language: navigator.language,
                }
              : {},
        },
      },
    });

    console.error('Error details:', error);
  }, [error, logErrorToRollbar]);

  return (
    <ErrorPage
      title="OOOPS"
      type="500"
      message="Something went wrong! Please retry later or contact our support team."
      buttonText="Go to Main"
      href={ROUTES.creators}
    />
  );
}
