import { useEffect, useState, useCallback, useRef } from 'react';
import type { Channel } from '@anycable/web';
import { getCable } from '../cable';

interface EventHandler {
  (data: any): void;
}

interface UseEventsSubscriptionOptions {
  onConnect?: () => void;
  onDisconnect?: () => void;
  onError?: (error: Error) => void;
  eventHandlers?: Record<string, EventHandler>;
}

export function useCableSubscription(userId: string, options: UseEventsSubscriptionOptions = {}) {
  const [isConnected, setIsConnected] = useState(false);
  const subscriptionRef = useRef<Channel | null>(null);
  const eventHandlersRef = useRef<Record<string, EventHandler>>({});

  // Initialize event handlers from options
  useEffect(() => {
    if (options.eventHandlers) {
      eventHandlersRef.current = { ...options.eventHandlers };
    }
  }, []);

  useEffect(() => {
    const cable = getCable();
    if (!cable || !userId) return;

    const subscription = cable.subscribeTo('EventsChannel', { user_id: userId });
    subscriptionRef.current = subscription;

    subscription.on('connect', () => {
      setIsConnected(true);
      options.onConnect?.();
    });

    subscription.on('disconnect', () => {
      setIsConnected(false);
      options.onDisconnect?.();
    });

    subscription.on('message', (data: any) => {
      try {
        const message = data?.message && JSON.parse(data.message);
        if (message) {
          const eventType = message.type;
          const payload = message.payload || message;

          const handler = eventHandlersRef.current[eventType];
          if (handler) {
            handler(payload);
          } else {
            const consol = console;
            consol.warn(`No handler registered for event type: ${eventType}`);
          }
        }
      } catch (error) {
        console.error('Error processing message:', error);
      }
    });

    return () => {
      subscription.disconnect();
      subscriptionRef.current = null;
    };
  }, [userId]);

  const addEventHandler = useCallback((eventType: string, handler: EventHandler) => {
    eventHandlersRef.current = {
      ...eventHandlersRef.current,
      [eventType]: handler,
    };
  }, []);

  const removeEventHandler = useCallback((eventType: string) => {
    const newHandlers = { ...eventHandlersRef.current };
    delete newHandlers[eventType];
    eventHandlersRef.current = newHandlers;
  }, []);

  return {
    isConnected,
    addEventHandler,
    removeEventHandler,
  };
}
