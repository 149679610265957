import { useEffect } from 'react';
import { useSocketEvents } from '../socket-provider';
import type { EventHandler } from '../types';

export function useEventSubscription<T>(eventType: string, handler: EventHandler<T>) {
  const events = useSocketEvents();

  useEffect(() => {
    if (!events) return;

    events.addEventHandler(eventType, handler);
    return () => events.removeEventHandler(eventType);
  }, [eventType, handler, events]);

  return events?.isConnected;
}
