import { createCable } from '@anycable/web';
import type { CableOptions } from './types';

// Create single cable instance
let cable: ReturnType<typeof createCable> | null = null;

export function initCable(options: CableOptions = {}) {
  if (typeof window === 'undefined') return null;

  const wsUrl = `${options.apiUrl || `${process.env['NEXT_PUBLIC_API_URL']}/streaming/cable`}`;

  cable = createCable(wsUrl, {
    logLevel: process.env.NODE_ENV === 'development' ? 'debug' : 'error',
    protocol: 'actioncable-v1-ext-json',
  });

  return cable;
}

export function getCable() {
  if (!cable) {
    cable = initCable();
  }
  return cable;
}
